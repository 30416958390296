import React, {useEffect, useMemo, useState} from 'react';
import {
    FlexColumnSection,
    FlexRowSection,
    SeparatorVerticalDiv,
    SmallHeader,
    SmallText
} from "../../styles/globalStyles";
import CustomCollapse from "../../components/UI/CustomCollapse";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {useDispatch, useSelector} from "react-redux";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {t} from "i18next";
import {
    CONFIRMATION_MODAL,
    openModal,
    SELECT_DATE_MODAL, SELECT_QUANTITY_MODAL, TRIAL_COURSE_SESSION_MODAL, updateModalRes,
} from "@arboxappv4/shared/src/redux/modalManagerSlice";
import {DB_DATE_FORMAT} from "@arboxappv4/shared/src/constants/constants";
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import styled from "styled-components";
import {Spin} from "antd";
import {
    addFiltersToQueryParams, checkIfHPGroupDisplay, getHPGroupIdFromLocation, getHPGroupsForPurchase,
    getLocationsForPurchase,
    getMembershipExpirationText,
    getMembershipPriceText, handleMembershipStartDate, onLinkClicked,
} from "../../helpers/functions";
import CustomSelect from "../../components/UI/CustomSelect";
import dayjs from "dayjs";
import useMembershipStartDate from "../../helpers/hooks/useMembershipStartDate";
import {updateFlags} from "@arboxappv4/shared/src/redux/stepperSlice";
import {useNavigate, useSearchParams} from "react-router-dom";
import {membershipTypes} from "@arboxappv4/shared/src/helpers/constants";

const Memberships = (props) => {
    const { values, errors, handleSubmit, setFieldValue, skipStep, blockOnLoggedOut, checkIfNoMembershipAllowed, HPGroupDisplay, hideLocation } = props;
    const box = useSelector(state => state.site.box)
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const quantitySessionRes = useSelector(state => state.modalManager[SELECT_QUANTITY_MODAL].processRes)
    const [memberships, setMemberships] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(values.locations_box_fk ?? null);
    const [selectedHPGroup, setSelectedHPGroup] = useState(null);

    const {isMobile} = useScreenSize()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
    useMembershipStartDate({values, setFieldValue, handleSubmit, blockOnLoggedOut})

    const isHPGroupDisplay = useMemo(() => box && HPGroupDisplay ? checkIfHPGroupDisplay() : false,[box])
    const locations = useMemo(() => isHPGroupDisplay ? [] : getLocationsForPurchase(checkIfNoMembershipAllowed),[box, isHPGroupDisplay])
    const groups = useMemo(() => isHPGroupDisplay ? getHPGroupsForPurchase() : [],[box, isHPGroupDisplay])

    const currencySymbol = useMemo(() => {
        if(box) {
            if(values.locations_box_fk) {
                const location = box.locations_box.find(location => location.id === values.locations_box_fk)
                return location?.currency_symbol
            } else if(selectedLocation) {
                const location = box.locations_box.find(location => location.id === selectedLocation)
                return location?.currency_symbol
            }
        }
    }, [box, values.locations_box_fk, selectedLocation]);
    const showNoMembershipOption = useMemo(() => {
        if(values.locations_box_fk && checkIfNoMembershipAllowed && box.locations_box.length) {
            const location = box.locations_box.find(location => location.id === values.locations_box_fk)
            return location?.disable_pages_app.some(item => item.section_name === 'registerWithoutMembership')
        }
        return false
    },[box, checkIfNoMembershipAllowed, values.locations_box_fk])
    const isNoPaymentLocation = useMemo(() => {
        if(selectedLocation) {
            const location = box.locations_box.find(location => location.id === selectedLocation)
            return !location?.hasPayments
        }
    }, [selectedLocation]);

    useEffect(() => {
        if(!skipStep) {
            setFieldValue('cart', null)
        } else {
            handleSubmit(values)
        }
    }, []);

    useEffect(() => {
        if(box && ((selectedLocation && !isHPGroupDisplay) || (isHPGroupDisplay && selectedHPGroup))) {
            getMemberships(selectedLocation)
        }
    }, [box, selectedLocation, selectedHPGroup]);

    useEffect(() => {
        if(globalLocation && (!values.locations_box_fk || !selectedLocation)) {
            // setFieldValue('locations_box_fk', globalLocation.id)
            setSelectedLocation(globalLocation.id)
        }
    }, [globalLocation]);

    useEffect(() => {
        if(selectedLocation) {
            if(isHPGroupDisplay) {
                const groupId = params.group ? parseInt(params.group) : null
                const isSpecificGroup = (!!groupId && groups.some(g => g.id === groupId)) ?? false;
                if(isSpecificGroup) {
                    setSelectedHPGroup(groupId)
                } else {
                    const selectedLocationGroupId = getHPGroupIdFromLocation(selectedLocation)
                    setSelectedHPGroup(selectedLocationGroupId)
                }
            }
            setFieldValue('locations_box_fk', selectedLocation)
            addFiltersToQueryParams(navigate, {location: selectedLocation})
        }
    }, [selectedLocation]);

    useEffect(() => {
        if(box && !isHPGroupDisplay && locations.length === 0) onLinkClicked('/404', navigate)
    }, [locations]);

    useEffect(() => {
        if(quantitySessionRes) {
            setFieldValue('cart', {...values.cart, quantity: quantitySessionRes})
            dispatch(updateModalRes({modalName: SELECT_QUANTITY_MODAL, res: null}))
            setTimeout(() => handleMembershipStartDate(values.cart, dispatch), 100)
        }
    }, [quantitySessionRes]);

    useEffect(() => {
        console.log('values',values)
    }, [values]);

    const getMemberships = async (locationId) => {
        setLoading(true)
        setMemberships(null)
        let params = {box_id: box.id, locations_box_id: locationId}
        if(isHPGroupDisplay) {
            params.group_id = selectedHPGroup
        } else {
            const locationHasMemberships = locations.some(l => l.id === locationId)
            if (!locationHasMemberships) {
                setSelectedLocation(locations[0].id)
                return null;
            }
        }
        let res = await apiAction('getSiteMemberships', 'post', params);
        let membershipsRes = res.data;

        if(isNoPaymentLocation) {
            membershipsRes = {plan: res.data.plan.filter(a => a.is_recurring_payment !== 1), session: res.data.session.filter(a => a.is_recurring_payment !== 1)}
        }
        setMemberships({plan: sortAlphabetically(membershipsRes.plan, 'name'), session: sortAlphabetically(membershipsRes.session, 'name')})
        setLoading(false)
    }

    const onMembershipClick = (item) => {
        setFieldValue('cart', item)
        if(item.type === membershipTypes.PUNCH_CARD && item.allow_quantity) {
            dispatch(openModal({modalName: SELECT_QUANTITY_MODAL}))
        } else {
            handleMembershipStartDate(item, dispatch)
        }
    }

    const onContinueWithoutMembershipClick = () => {
        dispatch(openModal({modalName: CONFIRMATION_MODAL, props: {
                header: t('continue-without-membership-confirm-header'),
                explanation: t('continue-without-membership-confirm-explain'),
                onConfirm: () => {
                    setFieldValue('continueWithoutMembership', true)
                    dispatch(updateFlags({continueWithoutMembership: true}))
                }
            }}))
    }

    const getHeaderInfoComp = (item) => {
        return (
            <>
                <span style={{width: isMobile ? '100%' : '27%'}}>{getMembershipPriceText(item, currencySymbol)}</span>
                <span style={{width: isMobile ? '100%' : '30%'}}>{getMembershipExpirationText(item)}</span>
            </>
        )
    }

    const getCollapseInfo = (item) => {
        if(isMobile) {
            return (
                <FlexColumnSection gap={'20px'} flex={'none'}>
                    {!item.description && item.type !== 'session' && item.limitations?.length === 0 && <SmallText>{t('membership-collapse-empty')}</SmallText>}
                    <FlexColumnSection flex={'unset'}>
                        {item.description &&
                        <FlexColumnSection flex={'unset'}>
                            <SmallText weight={'bold'}>{t('description')}:</SmallText>
                            <SmallText>{item.description}</SmallText>
                        </FlexColumnSection>
                        }
                        {item.type === 'session' && <SmallText><span style={{fontWeight: '600'}}>{t('price-per-session')}:</span>{` ${currencySymbol} ${(item.price/item.sessions).toFixed(1)}`}</SmallText>}
                    </FlexColumnSection>
                    {item.limitations?.length > 0 &&
                    <FlexColumnSection gap={'20px'} flex={'none'}>
                        {item.limitations.map(limitation => (
                            <FlexColumnSection key={`mobile-item-${item.id}-limitation-${limitation.header}`} flex={'none'}>
                                <SmallText weight={'bold'}>{limitation.header}</SmallText>
                                {limitation.values.map((value, i) => <SmallText key={`mobile-item-${item.id}-limitation-${limitation.header}-${i}`}>{value}</SmallText>)}
                            </FlexColumnSection>
                        ))}
                    </FlexColumnSection>
                    }
                </FlexColumnSection>
            )
        }
        return (
            <FlexRowSection align={'start'}>
                <FlexColumnSection flex={'unset'}>
                    {item.description &&
                        <FlexColumnSection flex={'unset'}>
                            <SmallText weight={'bold'}>{t('description')}:</SmallText>
                            <SmallText>{item.description}</SmallText>
                        </FlexColumnSection>
                    }
                    {item.type === 'session' && <SmallText><span style={{fontWeight: '600'}}>{t('price-per-session')}:</span>{` ${currencySymbol} ${(item.price/item.sessions).toFixed(1)}`}</SmallText>}
                </FlexColumnSection>
                {(item.description || item.type === 'session') && item.limitations?.length > 0 && <SeparatorVerticalDiv color={Colors.greyText} height={'100%'} margin={'0 30px'}/>}
                {item.limitations?.length > 0 &&
                    <div style={{display: 'grid', gridTemplateColumns: item.description ? 'auto auto' : 'auto auto auto', columnGap: '20px', rowGap: '15px', width:'100%'}} key={`item-${item.id}-limitations`}>
                        {item.limitations.map(limitation => (
                            <FlexColumnSection key={`item-${item.id}-limitation-${limitation.header}`}>
                                <SmallText weight={'bold'}>{limitation.header}</SmallText>
                                {limitation.values.map((value,i) => <SmallText key={`item-${item.id}-limitation-${limitation.header}-${i}`}>{value}</SmallText>)}
                            </FlexColumnSection>
                        ))}
                    </div>
                }
                {!item.description && item.type !== 'session' && item.limitations?.length === 0 && <SmallText>{t('membership-collapse-empty')}</SmallText>}
            </FlexRowSection>
        )
    }

    return (
        <FlexColumnPadding gap={'24px'} padding={isMobile ? '0 16px' : 0}>
            {!hideLocation &&
            <FlexRowSection flex={'none'}>
                {selectedLocation && memberships && locations.length > 1 && <CustomSelect onChange={(val) => val ? setSelectedLocation(val) : null} options={locations} defaultValue={selectedLocation} style={{flex: 1, maxWidth: '250px'}}/>}
                {selectedHPGroup && memberships && <CustomSelect onChange={(val) => val ? setSelectedHPGroup(val) : null} options={groups} defaultValue={selectedHPGroup} style={{flex: 1, maxWidth: '250px'}}/>}
            </FlexRowSection>}
            <Spin spinning={loading} style={{flex: 1}}/>
            {
                (memberships || showNoMembershipOption) &&
                <FlexColumnSection gap={'24px'} overflow={'hidden auto'}>
                    { showNoMembershipOption &&
                        <CustomCollapse headerText={t('register-without-membership')} ctaText={t('continue')} headerWidth={'auto'} headerInfo={<></>} onBtnClick={onContinueWithoutMembershipClick} key={'register-without-membership'}>
                            <SmallText>{t('register-without-membership-collapse')}</SmallText>
                        </CustomCollapse>
                    }
                    {
                        memberships && memberships.plan.length > 0 &&
                        <FlexColumnSection gap={'12px'} overflow={'unset'} flex={'none'}>
                            {memberships.session.length > 0 && <SmallHeader>{t('memberships')}</SmallHeader>}
                            {
                                memberships.plan.map(item => (
                                    <CustomCollapse headerText={item.name} headerInfo={getHeaderInfoComp(item)} onBtnClick={() => onMembershipClick(item)} key={`plan-membership-${item.id}`}>
                                        {getCollapseInfo(item)}
                                    </CustomCollapse>
                                ))
                            }
                        </FlexColumnSection>
                    }
                    {
                        memberships && memberships.session.length > 0 &&
                        <FlexColumnSection gap={'12px'} overflow={'unset'}>
                            {memberships.plan.length > 0 && <SmallHeader>{t('session-cards')}</SmallHeader>}
                            {
                                memberships.session.map(item => (
                                    <CustomCollapse headerText={item.name} headerInfo={getHeaderInfoComp(item)} onBtnClick={() => onMembershipClick(item)} key={`session-membership-${item.id}`}>
                                        {getCollapseInfo(item)}
                                    </CustomCollapse>
                                ))
                            }
                        </FlexColumnSection>
                    }
                </FlexColumnSection>
            }
        </FlexColumnPadding>
    );
};

export default Memberships;

const FlexColumnPadding = styled(FlexColumnSection)`
    padding: ${({padding}) => padding ?? 0};
`;