import axios from "axios";
import {store} from '../redux/store'
import {logout} from "../redux/authSlice";
import {getIdentifierFromSubDomain} from "../../../web/src/helpers/functions";

export const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

export const apiAction = async (apiCall, method = 'get', data = null) => {
    axiosInstance.defaults.headers.common.version = 10;
    axiosInstance.defaults.headers.common.accessToken = store.getState().auth.token
    axiosInstance.defaults.headers.common.refreshToken = store.getState().auth.refreshToken
    handleWebHeaders()

    const api = `${process.env.API_URL}${apiCall}?XDEBUG_SESSION_START=PHPSTORM`;
    const options = {
        method,
        url: api
    };
    if (data) {
        options.data = data;
    }
    console.log(api,method,data);
    return await call(options);
}

const call = async (options) => {
    try {
        const response = await axiosInstance.request(options);
        //TODO Note -> Sometimes we return structure that has data data or data
        return response.data;
    } catch (err) {
        console.log('error:' , err);
        if(!err?.response) throw err
        if (Object.prototype.hasOwnProperty.call(err.response.data, 'data')) {
            if(err.response.data.error){
                const error = err.response.data.error;
                if(error.code === 401){
                    store.dispatch(logout());
                }
            }

        }
        if (Object.prototype.hasOwnProperty.call(err.response.data, 'uniqueIdentifier')) {
            err.response.data = {
                // HERE IF SYSTEM FALLS ON UNKNOWN ERROR - RESET OBJECT TO PRINTED ONE
                statusCode: 0,
                error: {
                    message: 'unknown failure',
                    messageToUser: 'common:unknownFailure',
                    code: 0,
                },
                data: null
            };
        }
        console.log(err);
        throw err.response.data;
    }
}

const handleWebHeaders = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const site = store.getState().site;
    const identifier = getIdentifierFromSubDomain()

    axiosInstance.defaults.headers.common.boxFk = site.box?.id;
    axiosInstance.defaults.headers.common.whiteLabel = site.whitelabel;
    axiosInstance.defaults.headers.common.newSite = 1;
    if (site.box){
        axiosInstance.defaults.headers.common.identifier = site.box.external_url_id;
    } else if (identifier && !site.box) {
        axiosInstance.defaults.headers.common.identifier = params.identifier;
    }
    if(site.referrer) {
        axiosInstance.defaults.headers.common.refererName = site.referrer.toLowerCase();
    }
    if(site.isKiosk) {
        axiosInstance.defaults.headers.common.kiosk = true
    }
    axiosInstance.defaults.headers.common.lang = (site.language?.code ?? 'en').toLowerCase();

}