import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {setLang} from "@arboxappv4/shared/src/redux/siteSlice";
import LanguageDetector from "i18next-browser-languagedetector";
import {addFiltersToQueryParams} from "./functions";
import {store} from "@arboxappv4/shared/src/redux/store";

//this did not work when it was in the shared folder
i18next.use(initReactI18next).use(LanguageDetector).init({
    // debug: true,
    fallbackLng: ['en','he'],
});

//this is more for initializing the translations
export const changeLanguage = async (langObj, dispatch, navigate) => {
    try {
        const language = store.getState().site.language
        const res = await apiAction(`translation?langId=${langObj.id}`, 'get')
        i18next.addResourceBundle(langObj.code, 'translation', res);
        i18next.changeLanguage(langObj.code);
        if(langObj.id !== language?.id) {
            dispatch(setLang(langObj))
        }
        addFiltersToQueryParams(navigate, {lang: langObj.code})
    } catch(err) {
        console.error('Error fetching translations:', err);
    }
};

//this is when the user actively switches language. we want to change all the relevant props but not fetch the translations (because the page is reloading anyway)
const switchLangWithoutFetch = (langObj, dispatch, navigate) => {
    i18next.changeLanguage(langObj.code);
    dispatch(setLang(langObj))
    addFiltersToQueryParams(navigate, {lang: langObj.code})
}

export const changeLangByCode = (code, dispatch, navigate) => {
    if(code === 'en') {
        switchLangWithoutFetch({id: 1, code: 'en', label: 'English'}, dispatch, navigate)
    } else if(code === 'he') {
        switchLangWithoutFetch({id: 5, code: 'he', label: 'עברית'}, dispatch, navigate)
    }
    window.location.reload()
}

export const isRTL = () => i18next.dir() === 'rtl'