import React, {useEffect, useMemo} from 'react';
import {
    getDirectItemAdditionalInformationComponent,
    getDirectItemCta,
    getDirectItemDescriptionComponent, getDirectItemOnQuantityRes
} from "../FlowOptions";
import useScreenSize from "../../helpers/hooks/useScreenSize";
import {BlackBgHoverOutlineBtn, Btn, DisabledCta, FlexColumnSection, FlexRowSection} from "../../styles/globalStyles";
import {Divider} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useMembershipStartDate from "../../helpers/hooks/useMembershipStartDate";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from '@arboxappv4/shared/src/styles/Colors'
import {SELECT_QUANTITY_MODAL, updateModalRes} from "@arboxappv4/shared/src/redux/modalManagerSlice";

const SingleItemPage = (props) => {
    const { values, setFieldValue, handleSubmit, params } = props;
    const quantitySessionRes = useSelector(state => state.modalManager[SELECT_QUANTITY_MODAL].processRes)
    const {isMobile} = useScreenSize()
    const { t } = useTranslation() // normal t import doesn't work here + dynamic components need to rerender on t load
    const dispatch = useDispatch()
    useMembershipStartDate({values, setFieldValue, handleSubmit, blockOnLoggedOut: true})

    const cta = useMemo(() => Object.values(values).length ? getDirectItemCta(dispatch, params, values) : '', [values, t]);
    const descriptionComponent = useMemo(() => Object.values(values).length ? getDirectItemDescriptionComponent(values) : '', [values, t]);
    const additionalInformationComponent = useMemo(() => Object.values(values).length ? getDirectItemAdditionalInformationComponent(values) : '', [values, t]);

    useEffect(() => {
        if(quantitySessionRes) {
            const onQuantityRes = getDirectItemOnQuantityRes()
            if(onQuantityRes) {
                onQuantityRes(quantitySessionRes, values, setFieldValue, dispatch)
            }
        }
    }, [quantitySessionRes]);

    return (
        <div style={{flex: 1}}>
            {
                isMobile ?
                    <FlexColumnSection gap={'10px'} padding={'0 16px'} height={'100%'} justify={'space-between'}>
                        <FlexColumnSection flex={'unset'}>
                            {additionalInformationComponent}
                            <Divider style={{margin: '15px 0'}}/>
                            {descriptionComponent}
                        </FlexColumnSection>
                        {cta.onClick ? <Btn type={'primary'} width={'100%'} margin={'0 0 10px'} onClick={cta.onClick}>{cta?.text}</Btn> : <DisabledCta isMobile={true} margin={'0 0 10px'} bg={Colors.white}>{cta?.text}</DisabledCta>}
                    </FlexColumnSection>
                    :
                    <FlexRowSection gap={'50px'} align={'start'}>
                        <DesktopContentBox>{descriptionComponent}</DesktopContentBox>
                        <DesktopContentBox width={'250px'} flex={'unset'}>
                            {additionalInformationComponent}
                            {cta.onClick ? <BlackBgHoverOutlineBtn onClick={cta.onClick}>{cta?.text}</BlackBgHoverOutlineBtn> : <DisabledCta isMobile={false}>{cta?.text}</DisabledCta>}
                        </DesktopContentBox>
                    </FlexRowSection>
            }
        </div>
    );
};

export default SingleItemPage;

const DesktopContentBox = styled(FlexColumnSection)`
    padding: 20px;
    background-color: white;
    gap: 15px;
    border-radius: 5px;
    width: ${({width}) => width ?? 'unset'};
`;

