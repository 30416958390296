import React, {useEffect, useState} from 'react';
import {getFiltersConfig} from "@arboxappv4/shared/src/helpers/filters";
import {t} from "i18next";
import styled from "styled-components";
import {isRTL} from "../../../helpers/i18n";
import FiltersHandler from "../../../components/FiltersHandler";
import {FlexRowSection} from "../../../styles/globalStyles";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";

const ShopHeader = (props) => {
    const { items, onFiltered, selectedLocation } = props;
    const [filtersConfig, setFiltersConfig] = useState(null);
    const {isMobile} = useScreenSize()

    useEffect(() => {
        if(filtersConfig === null && items && selectedLocation) {
            initFiltersConfig()
        }
    }, [items, selectedLocation]);

    const initFiltersConfig = () => {
        const config = getFiltersConfig({
            locations: {rest: {style: {width: isMobile ? '100%' : '250px'}}, filterLocations: (location) => location.hasProducts, hideAllLocations: true},
            sortOption: {rest: {style: {width: isMobile ? '100%' : '200px'}, suffixIcon: light('arrow-up-arrow-down')}}
        }, selectedLocation, t)
        setFiltersConfig(config)
    }

    return (
        <>
            {isMobile ?
                <MobileAbsoluteFilter rtl={isRTL()}>
                    {filtersConfig && <FiltersHandler unfilteredData={items} onFiltered={onFiltered} initConfig={filtersConfig}/>}
                </MobileAbsoluteFilter>
                :
                <FlexRowSection flex={'unset'}>
                    {filtersConfig && <FiltersHandler unfilteredData={items} onFiltered={onFiltered} initConfig={filtersConfig} oldStyle={true}/>}
                </FlexRowSection>
            }
        </>
    );
};

export default ShopHeader;


const MobileAbsoluteFilter = styled.div`
    position: absolute;
    top: 25px;
    right: ${({rtl}) => rtl ? 'unset' : '20px'};
    left: ${({rtl}) => rtl ? '20px' : 'unset'};
`;