import React, {useEffect, useMemo, useState} from 'react';
import FiltersHandler from "../../../components/FiltersHandler";
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import {getFiltersConfig} from "@arboxappv4/shared/src/helpers/filters";
import {t} from "i18next";
import {useSelector} from "react-redux";
import {FlexRowSection} from "../../../styles/globalStyles";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import styled from "styled-components";
import {isRTL} from "../../../helpers/i18n";
import {bookingTypes, membershipTypes} from "@arboxappv4/shared/src/helpers/constants";

const CoursesHeader = (props) => {
    const { courses, onFiltered, selectedLocation } = props;
    const userBox = useSelector(state => state.user.activeUsersBoxes)
    const loggedIn = useSelector(state => state.auth.loggedIn)
    const [filtersConfig, setFiltersConfig] = useState(null);
    const {isMobile} = useScreenSize()

    const coursesFlat = useMemo(() => courses ? Object.values(courses).reduce((acc, coursesByType) => [...acc, ...coursesByType],[]) : null, [courses]);

    const hasTrial = useMemo(() => {
        if(coursesFlat && !loggedIn) {
            const trialSessions = coursesFlat.filter(course => {
                const membershipType = course?.membership_types.find(item => item.type === membershipTypes.TRIAL)
                return !!membershipType
            })
            if(trialSessions.length > 0) {
                return trialSessions.filter(course => !course.isHugFull && !course.past).length > 0
            }
        }
        return false
    }, [coursesFlat, loggedIn]);

    const boxCategories = useMemo(() => {
        if(coursesFlat) {
            const optionsObj = coursesFlat.reduce((acc, course) => ({...acc, [`type-${course.box_category_fk}`]: course.box_categories}), {})
            return sortAlphabetically(Object.values(optionsObj), 'name')
        }
    }, [coursesFlat]);

    const coaches = useMemo(() => {
        if(coursesFlat) {
            const optionsObj = coursesFlat.reduce((acc, course) => {
                let tempRes = acc
                if (course.coach) {
                    tempRes = {...tempRes, [`coach-${course.coach_fk}`]: course.coach}
                }
                if (course.second_coach) {
                    tempRes = {...tempRes, [`coach-${course.second_coach_fk}`]: course.second_coach}
                }
                return tempRes
            }, {})
            return sortAlphabetically(Object.values(optionsObj), 'full_name')
        }
    }, [coursesFlat]);

    const spaces = useMemo(() => {
        if(coursesFlat && filtersConfig) {
            const optionsObj = coursesFlat.reduce((acc, course) => (course.spaces_id ? {...acc, [`space-${course.spaces_id}`]: course.spaces} : acc), {})
            return sortAlphabetically(Object.values(optionsObj), 'name')
        }
    }, [coursesFlat]);

    const customFields = useMemo(() => {
        if(coursesFlat) {
            const customFieldsObj = coursesFlat.reduce((acc, course) => {
                if(course.custom_field_value.length) {
                    let cfObj = course.custom_field_value.reduce((acc2, cfv) => ({...acc2, [`cf-${cfv.custom_field.id}`]: cfv.custom_field}), {})
                    return {...acc, ...cfObj}
                }
                return acc
            }, {})
            return Object.values(customFieldsObj)
        }
    }, [coursesFlat]);

    useEffect(() => {
        if(boxCategories && coaches) {
            initFiltersConfig()
        }
    }, [boxCategories, coaches, spaces, userBox, customFields, hasTrial]);

    const initFiltersConfig = () => {
        console.log('hasTrial',hasTrial)
        const config = getFiltersConfig({
            boxCategories,
            coaches,
            locations: true,
            courseVacancy: true,
            spaces,
            online: true,
            trial: hasTrial,
            customFields
        }, selectedLocation, t)
        setFiltersConfig(config)
    }

    return (
        <>
            {isMobile ?
                <MobileAbsoluteFilter rtl={isRTL()}>
                    {filtersConfig && <FiltersHandler unfilteredData={courses} onFiltered={onFiltered} initConfig={filtersConfig}/>}
                </MobileAbsoluteFilter>
                :
                <FlexRowSection flex={'unset'}>
                    {filtersConfig && <FiltersHandler unfilteredData={courses} onFiltered={onFiltered} initConfig={filtersConfig}/>}
                </FlexRowSection>
            }
        </>
    );
};

export default CoursesHeader;

const MobileAbsoluteFilter = styled.div`
    position: absolute;
    top: 25px;
    right: ${({rtl}) => rtl ? 'unset' : '20px'};
    left: ${({rtl}) => rtl ? '20px' : 'unset'};
`;