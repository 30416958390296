import React, {useEffect, useState} from 'react';
import {apiAction} from "@arboxappv4/shared/src/helpers/HTTP";
import {useSelector, useDispatch} from "react-redux";
import {sortAlphabetically} from "@arboxappv4/shared/src/helpers/functions";
import ShopItem from "./ShopItem";
import './shop.scss'
import {Spin} from "antd";
import ShopHeader from "./ShopHeader";
import ShopItemDirect from "./ShopItemDirect";
import {FlexColumnSection} from "../../../styles/globalStyles";
import useScreenSize from "../../../helpers/hooks/useScreenSize";
import {addFiltersToQueryParams, onLinkClicked} from "../../../helpers/functions";
import {useNavigate} from "react-router-dom";

const Shop = (props) => {
    const { values, handleSubmit, setFieldValue, isDirectItem, isOneTimeLink } = props;
    const globalLocation = useSelector(state => state.site.selectedLocation)
    const box = useSelector(state => state.site.box)
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [products, setProducts] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const {isMobile} = useScreenSize()
    const navigate = useNavigate();

    useEffect(() => {
        if(readyToSubmit && values.cart && values.locations_box_fk) {
            setReadyToSubmit(false)
            handleSubmit()
        }
    }, [values.cart, values.locations_box_fk]);

    useEffect(() => {
        if(globalLocation) {
            if(globalLocation.hasProducts) {
                setSelectedLocation(globalLocation?.id)
            } else {
                const validLocation = box.locations_box.find(location => location.hasProducts)
                if(validLocation) {
                    setSelectedLocation(validLocation?.id)
                } else {
                    if(!isOneTimeLink) {
                        onLinkClicked('/404', navigate)
                    }
                }
            }
        }
    }, [globalLocation]);

    useEffect(() => {
        if(selectedLocation) {
            getProducts()
        }
    }, [selectedLocation]);

    const getProducts = async () => {
        setFetching(true)
        setProducts(null)
        setFilteredProducts(null)
        const productsRes = await apiAction(`shop/getProducts`, 'post', {location_id: selectedLocation === 'all' ? null : selectedLocation});
        const sorted = sortAlphabetically(productsRes.data, 'name');
        setProducts(sorted)
        setFilteredProducts(sorted)
        if (selectedLocation !== 'all') addFiltersToQueryParams(navigate, {location: selectedLocation})
        setFetching(false)
    }

    const onItemSelected = (item) => {
        setFieldValue('locations_box_fk', selectedLocation)
        setFieldValue('cart', item)
        setReadyToSubmit(true)
    }

    const onFilterChange = (filteredItems, filtersConfig) => {
        if(filtersConfig?.locations) setSelectedLocation(filtersConfig?.locations.selected ?? null)
        setFilteredProducts(filteredItems)
    }

    return (
        <>
            {isDirectItem ? <ShopItemDirect item={values.cart} onPurchase={onItemSelected} isModal={false}/> :
            <FlexColumnSection gap={'15px'}>
                <div style={{width: '50%'}}><ShopHeader items={products} onFiltered={onFilterChange} selectedLocation={selectedLocation}/></div>
                <Spin spinning={fetching}/>
                <div className={'shop-items-wrapper'}>
                    {filteredProducts && filteredProducts.map(item => <ShopItem key={`shop-item-${item.id}`} item={item} onItemSelected={onItemSelected}/>)}
                </div>
            </FlexColumnSection>
            }
        </>
    );
};

export default Shop;